

.bakdRoP{
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    background-color: #00000098;
    /* background-color: red; */
    z-index: 9999999999999999 !important;
}



.modal_bx_KJUIJKNhyj{
    min-width: 460px;
    max-width: 500px;
    position: absolute;
    top: 40%;
    left: 50%;
    padding: 1rem 2rem;
    background: white;
    transform: translate(-50%,-50%);
}

.title{
    font-size: 1.2rem;
    margin-top: 14px;
}

.form-group {
    margin-bottom: 1rem;
}

.form-control{
    padding: 0.6rem;
    border: 0.05rem solid #768290;
    min-width: calc(100% - 1rem);
    font-size: .9rem;
    border-radius: 0.2rem;
    background: #fff;
    transition: .2s all ease;
    
}

.form-control:focus{
    border: 2px solid #333;
    box-shadow: 4px 4px 9px #eaeaea;
}


.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    border: none;
    background-color: #2f70df;
    color: #fff;
    padding: 0.7rem;
    font-size: 14px;
}

.btdn:hover{
    color: #fff !important;
}

.logo{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #000;
    border: 0.05rem solid #768290;
}

.msss{
    width: 1.3rem;
    margin-right: 0.4rem;
}

.btn-grp{
    display: grid;
    gap: 0.5rem;
}

.err_msg{
    color: #ba1100;
    font-size: 12px;
}

.ld_wrap_kjhtyUJH{
    display: flex;
    justify-content: center;
}

.laoder_gif{
    z-index: 9999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}