
.logo_sharepoint_container{
    width: 100%;
    height: 8vh !important;
    /* border: 1px solid #000; */
    background: #fff;
    border-bottom: 1px solid #ededed;
}

.logo_share_point_i_m_g_{
    height: 60px;
    margin-left: 10rem;
    padding-top: 7px;

}

.f_video_player_{
    width: 100% !important;
    /* height: 82vh !important; */
    /* border: 1px solid red; */
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: -10;
    min-width: 100% !important; 
    min-height: 100% !important;
  
}

.hey{
    margin-top: 3.7em;
}

.land_pg_p_ik{
    vertical-align: middle !important;
    border-style: none;
    height: 90px;
    margin: 0 auto !important;
    display: flex;
    justify-content: center;
    /* position: relative; */
    top: 3em;
    cursor: grab;
}

.land_p_g_txt_contnt{
    text-align: center !important;
}

.w_el_cm_txt_{
    color: #fff;
    /* color: #ea3e23; */
    font-size: 3em;
    /* font-family: "Ubuntu", sans-serif; */
    font-family: "Roboto", sans-serif;
    margin-top: 1em;
}

.co_pilo_{
    font-weight: bold;
    font-family: "Ubuntu", sans-serif;
    font-size: 4em;
    user-select: none !important;
}

.e_ffec_t{
    color: #07d;
    font-family: "Roboto", sans-serif;
    font-size: 24px;

}

.btn_wrappe_r{
    margin: 0 auto !important;
    display: flex;
    justify-content: center;
    margin-top: 3em !important;
}

.bbtnn{
    transition: all .1s linear;
}

.bbtnn:hover{
    color: #fff;
    background: #a9301e !important;
    
}

.bbtnn:active{
    padding: 21px 44px !important;
}

.btm{
    font-size: 11.6px;
    text-align: center !important;
}

.logo_sharepoint_container_null{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
}

.log_article_0UIOJoiur_4ruiod{
    border: 1px solid #000;
    background: #fff;
    width: 180px;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 1em;
}

.tsxt_log_urufjru8{
    font-size: 15px;
}

.qMarK_cont{
    border: 1px solid #000;
    background: #fff;
    width: 40px;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    display: flex;
    margin-right: 1em;
    justify-content: center;
    align-items: center;
}

.modal_drop_bx_lkm3uiejreyuij{

    /* width: 200px;
    height: 200px;
    background: #fff;
    border: 1px solid #fff; */




    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    width: 27rem;
    border-radius: 5px;
    border: 1px solid #ddd;
    height: auto !important;
}

.file_hder_knedjdnty7uj{
    text-align: center;
    padding: 2rem 1rem;
}

.item_kyujnYTUIj{
    font-weight: bold;
    font-size: 19px;
    margin-top: 1em;
}

.expir{
    color: #999;
    font-size: 14px;
}

.bdr_lkeudijnGYUIj{
    /* border: 1px solid #000; */
    color: #666;
    /* width: 200px; */
    /* height: 30px; */
}

.dolwnld_PkgyuiHGTYuhjv{
    color: #07d;
    font-size: 25px;
    cursor: pointer;
}

.pffff{
    height: 50px;
    cursor: zoom-in;
}

.imags_files_desc_jyuiJHBTRyuijhg{
    display: flex;
    align-items: center;
}

.file__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 9px;
    padding-right: 9px;
    padding-bottom: 30px;
}

.btnnn{
    border: none;
    padding: 0.7rem 1.2rem;
    border-radius: 5px;
    background-color: #2f70df;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    margin-right: 1em;
}

.ppppwwww{
    font-size: .9rem;
    color: #2f93ce;
    cursor: pointer;
    margin-left: 1em;
}

.ft_art{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5em;
}